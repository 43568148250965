


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

// TODO: get rid of class name "section-event-list" in favour of "events"

@Component({
  name: 'events',
})
export default class Events extends Vue {

}
